import React from 'react'
import {
  Box,
  Heading,
  Img,
  Stack,
  VStack,
  Text,
  Container,
  List,
  ListItem,
} from '@chakra-ui/react'
import { GetStartedButton } from '../../../components'
import FeatureItem from './FeatureItem'

const featureItems: { heading: string; text: string }[] = [
  {
    heading: 'Get access to on-demand clinical expertise',
    text: `Getting access to qualified physicians for your venture
  shouldn’t be an ordeal. We’re bringing together a network of
  medical experts ready to help you build your company.`,
  },
  {
    heading: 'Save time and money',
    text: `Prototyping and testing new products in healthcare is expensive
    and time-consuming. By helping you clinically validate your
    concept now, we can help you avoid costly mistakes later.`,
  },
  {
    heading: 'Move forward with confidence',
    text: `Health innovation shouldn’t be a shot in the dark. We can help
    you understand the clinical realities of the space you are
    trying to disrupt so that you can feel confident in growing your
    venture.`,
  },
]

const Section2: React.FC = () => {
  return (
    <Box backgroundColor={'#00312E'} color={'#ffffff'} position={'relative'}>
      <Box
        position={'absolute'}
        top={['-60px', '-60px', '-60px', '-180px']}
        borderLeft={'100vw solid transparent'}
        borderBottomColor={'#00312E'}
        borderBottomStyle={'solid'}
        borderBottomWidth={['61px', '61px', '61px', '181px']}
      />
      <Img
        position={'absolute'}
        left={['-50px', '-50px', '-50px', '0px']}
        top={'-60px'}
        zIndex={4}
        src={require('./top-border-decorator.svg')}
      />
      <Container
        px={0}
        width={'82%'}
        maxWidth={1196}
        pt={['65px', '65px', '65px', '160px']}
        pb={['80px', '80px', '80px', '140px']}
      >
        <Stack
          direction={['column', 'column', 'column', 'row']}
          spacing={['60px', '60px', '60px', '60px']}
          alignItems={['center', 'center', 'center', 'flex-start']}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <VStack
            spacing={['30px', '30px', '30px', '44px']}
            align={['center', 'center', 'center', 'flex-start']}
          >
            <VStack
              spacing={['6px', '6px', '6px', '20px']}
              maxWidth={'574px'}
              mx={['auto', 'auto', 'auto', '']}
            >
              <Heading
                fontSize={['32px', '32px', '32px', '64px']}
                lineHeight={['42px', '42px', '42px', '80px']}
                fontWeight={700}
              >
                A world of clinical insight, one call away.️ 📞
              </Heading>
              <Text
                fontSize={['16px', '16px', '16px', '18px']}
                lineHeight={['26px', '26px', '26px', '28px']}
                fontWeight={300}
              >
                To save you the time, money, and headaches that come with going
                it alone, we’re building a network of physicians dedicated to
                helping your venture succeed. Your investors will wonder when
                you found the time to go to medical school.
              </Text>
            </VStack>
            <GetStartedButton />
          </VStack>
          <List
            spacing={['36px', '36px', '36px', '26px']}
            maxWidth={['574px', '574px', '574px', '428.51px']}
          >
            {featureItems.map(({ heading, text }, index) => (
              <ListItem key={index}>
                <FeatureItem key={index} heading={heading} text={text} />
              </ListItem>
            ))}
          </List>
        </Stack>
      </Container>
      <Box
        position={'absolute'}
        bottom={['-60px', '-60px', '-60px', '-180px']}
        zIndex={2}
        borderRight={'100vw solid transparent'}
        borderTopColor={'#00312E'}
        borderTopStyle={'solid'}
        borderTopWidth={['61px', '61px', '61px', '181px']}
      />
      <Img
        position={'absolute'}
        right={'0px'}
        bottom={['-66px', '-66px', '-66px', '-60px']}
        zIndex={4}
        src={require('./bottom-border-decorator.svg')}
      />
    </Box>
  )
}

export default Section2
