import React, { useState, useRef } from 'react'
import {
  Box,
  Heading,
  Stack,
  VStack,
  Text,
  Container,
  Img,
} from '@chakra-ui/react'
import { Button, LeadGenModal } from '../../../components'

const Section2: React.FC = () => {
  const [leadGenModalOpen, setLeadGenModalOpen] = useState(false)
  const modalFinalFocusRef = useRef()
  return (
    <>
      <Box backgroundColor={'#f9f9f9'} color={'#000'} position={'relative'}>
        <Container
          px={0}
          width={'82%'}
          maxWidth={'749px'}
          mx={'auto'}
          pt={['160px', '160px', '160px', '280px']}
          pb={['174px', '174px', '174px', '140px']}
        >
          <VStack spacing={['20px', '20px', '20px', '50px']}>
            <VStack spacing={['20px', '20px', '20px', '50px']}>
              <Heading
                fontWeight={700}
                fontSize={['24px', '24px', '24px', '30px']}
                lineHeight={['36px', '36px', '36px', '45px']}
                textAlign={'center'}
              >
                We know that health innovation is tough.
              </Heading>

              <Stack
                direction={['column', 'column', 'column', 'row']}
                spacing={['40px', '40px', '40px', '69px']}
              >
                <Text fontSize={'16px'} lineHeight={'26px'} maxWidth={'340px'}>
                  With all the marketing, product development, hiring, and
                  fundraising you have to do, the last thing you want to spend
                  time on is cold-calling doctors’ offices and hoping you’ll
                  find someone who can help you succeed.
                </Text>
                <Text fontSize={'16px'} lineHeight={'26px'} maxWidth={'340px'}>
                  At Ursanex, we’re building the tools and network to guide you
                  through the clinical landscape of the problem you’re trying to
                  solve and will bring you the intel you need. You can keep
                  grinding while we deliver the clinical insight.
                </Text>
              </Stack>
            </VStack>
            <VStack spacing={['30px', '30px', '30px', '50px']}>
              <Box
                width={'3px'}
                height={['80px', '80px', '80px', '140px']}
                backgroundColor={'#00FFF0'}
                css={{ content: '' }}
              />
              <Stack
                width={'100%'}
                mx={'auto'}
                direction={['column', 'column', 'column', 'row']}
                spacing={['20px', '20px', '20px', '69px']}
              >
                <Box width={['100%', '100%', '100%', '50%']}>
                  <Img
                    width={'100%'}
                    maxWidth={'340px'}
                    mx={'auto'}
                    boxShadow={'0px 0px 15px 6px rgba(126, 126, 126, 0.25)'}
                    src={require('../../../components/LeadGenModal/think-like-a-doctor-pdf-cover-image.png')}
                    alt={'Think Like a Doctor PDF cover'}
                  />
                </Box>
                <VStack
                  width={['100%', '100%', '100%', '50%']}
                  spacing={['20px', '20px', '20px', '24px']}
                  align={['center', 'center', 'center', 'flex-start']}
                >
                  <Text
                    maxWidth={'317px'}
                    ref={modalFinalFocusRef}
                    textAlign={['center', 'center', 'center', 'left']}
                    fontSize={['16px', '16px', '16px', '24px']}
                    lineHeight={['26px', '26px', '26px', '32px']}
                    fontWeight={[600, 600, 600, 700]}
                  >
                    3 tips to win physician buy-in. Unlock them now by
                    downloading our free PDF.
                  </Text>
                  <Button onClick={() => setLeadGenModalOpen(true)}>
                    Download our free PDF
                  </Button>
                </VStack>
              </Stack>
            </VStack>
          </VStack>
        </Container>
        <Box
          position={'absolute'}
          left={['-65px', '-65px', '-65px', '-40px']}
          bottom={['-95px', '-95px', '-95px', '-175px']}
          zIndex={2}
          width={['214px', '214px', '214px', '356px']}
          height={['244px', '244px', '244px', '340px']}
          backgroundImage={`url('${require('./design-element.svg')}')`}
          backgroundRepeat={'no-repeat'}
          backgroundSize={'cover'}
        />
      </Box>
      <LeadGenModal
        isOpen={leadGenModalOpen}
        finalFocusRef={modalFinalFocusRef}
        onClose={() => {
          setLeadGenModalOpen(false)
        }}
      />
    </>
  )
}

export default Section2
