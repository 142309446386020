import React from 'react'
import { VStack, Heading, Text } from '@chakra-ui/react'

interface IFeatureItemProps {
  heading: string
  text: string
}

const FeatureItem: React.FC<IFeatureItemProps> = ({ heading, text }) => {
  return (
    <VStack spacing={'2px'} alignItems={'flex-start'}>
      <Heading
        fontSize={['18px', '24px']}
        lineHeight={['30px', '36px']}
        fontWeight={600}
      >
        {heading}
      </Heading>
      <Text fontWeight={400} fontSize={'16px'} lineHeight={'24px'}>
        {text}
      </Text>
    </VStack>
  )
}

export default FeatureItem
