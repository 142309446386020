import React from 'react'
import { PageProps } from 'gatsby'

import { Layout } from '../../components'
import TitleSection from './TitleSection'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'
import Section5 from './Section5'
import Section6 from './Section6'

const Homepage = (props: PageProps) => {
  return (
    <Layout>
      <section id={'title'}>
        <TitleSection />
      </section>
      <section id={'section-1'}>
        <Section1 />
      </section>
      <section id={'section-2'}>
        <Section2 />
      </section>
      <section id={'section-3'}>
        <Section3 />
      </section>
      <section id={'section-4'}>
        <Section4 />
      </section>
      <section id={'section-5'}>
        <Section5 />
      </section>
      <section id={'section-6'}>
        <Section6 />
      </section>
    </Layout>
  )
}

/*
export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`
*/

export default Homepage
