import React from 'react'
import { Box } from '@chakra-ui/react'
import { Footer } from '.'

const Layout = ({ children }) => {
  return (
    <Box width={'100%'} height={'100%'}>
      {children}
      {<Footer />}
    </Box>
  )
}

export default Layout
