import React, { useState, useRef } from 'react'
import {
  Box,
  Container,
  VStack,
  HStack,
  Heading,
  SimpleGrid,
  GridItem,
  Img,
  Link,
  Text,
} from '@chakra-ui/react'
import { LinkedInIcon } from '../../../components/Icons'
import BiographyModal from './BiographyModal'
import teamMembers from './teamMembers'

const Section3: React.FC = () => {
  const [viewingTeamMemberBio, setViewingTeamMemberBio] = useState<number>(-1)
  const modalFinalFocusRef = useRef()
  return (
    <>
      <Box py={['60px', '60px', '60px', '100px']}>
        <Container px={0} width={'82%'} maxWidth={'710px'} margin={'auto'}>
          <VStack spacing={['20px', '20px', '20px', '30px']} width={'100%'}>
            <Heading fontSize={'24px'} lineHeight={'36px'} fontWeight={700}>
              Meet Our Team
            </Heading>
            <SimpleGrid
              ref={modalFinalFocusRef}
              maxWidth={'710px'}
              width={'100%'}
              margin={'auto'}
              columns={2}
              columnGap={['15px', '15px', '15px', '24px']}
              rowGap={['56px', '56px', '56px', '50px']}
            >
              {teamMembers.map(
                ({ photoSrc, heading, subheading, socialLinks }, index) => (
                  <GridItem
                    key={index}
                    maxWidth={'343px'}
                    cursor={'pointer'}
                    onClick={() => setViewingTeamMemberBio(index)}
                  >
                    <VStack
                      spacing={['10px', '10px', '10px', '12px']}
                      width={'100%'}
                    >
                      <Box
                        maxWidth={'343px'}
                        maxHeight={'343px'}
                        backgroundColor={'#F1F1F1'}
                      >
                        <Img
                          src={photoSrc}
                          alt={heading}
                          objectFit={'fill'}
                          width={'100%'}
                          height={'100%'}
                        />
                      </Box>
                      <VStack
                        spacing={['2px', '2px', '2px', '6px']}
                        align={'flex-start'}
                        width={'100%'}
                      >
                        <HStack
                          width={'100%'}
                          align={'center'}
                          justify={'space-between'}
                        >
                          <Text
                            fontWeight={700}
                            fontSize={['12px', '12px', '12px', '18px']}
                            lineHeight={['18px', '18px', '18px', '27px']}
                          >
                            {heading}
                          </Text>
                          {socialLinks?.map(({ type, url }) => (
                            <Link href={url} target={'_blank'}>
                              {type === 'linkedIn' && (
                                <LinkedInIcon
                                  width={['14px', '14px', '14px', '27px']}
                                  height={['14px', '14px', '14px', '27px']}
                                />
                              )}
                            </Link>
                          ))}
                        </HStack>
                        <Text
                          color={'#828282'}
                          fontSize={['10px', '10px', '10px', '12px']}
                          lineHeight={['15px', '15px', '15px', '18px']}
                        >
                          {subheading}
                        </Text>
                      </VStack>
                    </VStack>
                  </GridItem>
                ),
              )}
            </SimpleGrid>
          </VStack>
        </Container>
      </Box>
      <BiographyModal
        isOpen={viewingTeamMemberBio > -1}
        teamMemberId={viewingTeamMemberBio}
        finalFocusRef={modalFinalFocusRef}
        onClose={() => {
          setViewingTeamMemberBio(-1)
        }}
      />
    </>
  )
}

export default Section3
