interface ITeamMember {
  photoSrc: string
  heading: string
  subheading: string
  bio: string
  socialLinks?: { type: 'linkedIn'; url: string }[]
}

const teamMembers: ITeamMember[] = [
  {
    photoSrc: require('./jon.jpg'),
    heading: 'Jon Vu, MD',
    subheading: 'Co-founder/CEO',
    bio:
      'As a result of his experiences as a medical trainee, research fellow in machine learning, and research associate at MIT Sloan, Jon believes that some of the best opportunities to transform healthcare will come from effectively directing the tools of technology and finance toward medicine’s biggest problems. It’s for this reason that he co-founded and leads Ursanex. He graduated from the Warren Alpert Medical School of Brown University, where he founded the Brown Medical Venture Group, an organization that recruits medical students to help early-stage innovators and investors grow new health ventures.',
    socialLinks: [
      {
        type: 'linkedIn',
        url: 'https://www.linkedin.com/in/jonathan-vu-b80443102/',
      },
    ],
  },
  {
    photoSrc: require('./anshul.jpg'),
    heading: 'Anshul Parulkar, MD',
    subheading: 'Co-founder/Advisor',
    bio:
      'Anshul graduated with a degree in Economics and began his career as an investment banker in the healthcare and technology sectors prior to attending medical school. He has performed both clinical and basic research in vascular biology and cardiovascular medicine and is embarking on a fellowship in cardiology. He has an interest in personalized medicine, remote cardiac monitoring, and medical devices.',
  },
  {
    photoSrc: require('./james.jpg'),
    heading: 'James Fingleton, MD',
    subheading: 'Founding Investor/Clinical Advisor',
    bio: 'No biography yet.',
  },
  {
    photoSrc: require('./phil.jpg'),
    heading: 'Phil Rizzuto, MD',
    subheading: 'Founding Investor/Clinical Advisor',
    bio: 'No biography yet.',
  },
]

export default teamMembers
