import React, { useState } from 'react'
import { VStack, HStack, Input, Text, Button, Box } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

interface IProps {
  portalId: string
  formGuid: string
}

// TODO: Keep height same after submit
const Form: React.FC<IProps> = ({ portalId, formGuid, onSubmitted }) => {
  const [state, setState] = useState<'ready' | 'submitting' | 'submitted'>(
    'ready',
  )

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async ({ firstName, lastName, email }) => {
    setState('submitting')
    try {
      const response = await fetch(
        // TODO: Set state submitting
        // TODO: Add additional context information (i.e., url user was one)
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fields: [
              {
                name: 'firstname',
                value: firstName,
              },
              {
                name: 'lastname',
                value: lastName,
              },
              {
                name: 'email',
                value: email,
              },
            ],
          }),
        },
      )
      if (response.ok) {
        setState('submitted')
        return
      }
      setState('ready')
      // TODO: Handle returned errors
    } catch (error) {
      alert('Could not submit form')
      setState('ready')
      console.error(error)
      //
    }
  }

  return (
    <Box>
      {(state === 'ready' || state === 'submitting') && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack spacing={'13px'} width={'100%'}>
            <VStack spacing={'10px'} align={'flex-start'} width={'100%'}>
              <VStack spacing={'10px'} width={'100%'}>
                <HStack spacing={'4px'} width={'100%'}>
                  <Input
                    name={'firstName'}
                    ref={register}
                    isInvalid={!!errors.firstName}
                    disabled={state === 'submitting'}
                    isRequired
                    _placeholder={{ color: 'rgba(255, 255, 255, 0.6)' }}
                    css={`
                      border-color: rgba(255, 255, 255, 0.6);
                    `}
                    width={'50%'}
                    focusBorderColor={'#009E95'}
                    placeholder={'First Name'}
                    variant={'flushed'}
                  />
                  <Input
                    name={'lastName'}
                    ref={register}
                    isInvalid={!!errors.lastName}
                    disabled={state === 'submitting'}
                    isRequired
                    _placeholder={{ color: 'rgba(255, 255, 255, 0.6)' }}
                    css={`
                      border-color: rgba(255, 255, 255, 0.6);
                    `}
                    width={'50%'}
                    focusBorderColor={'#009E95'}
                    placeholder={'Last Name'}
                    variant={'flushed'}
                  />
                </HStack>
                <Input
                  type={'email'}
                  name={'email'}
                  ref={register}
                  isInvalid={!!errors.email}
                  disabled={state === 'submitting'}
                  isRequired
                  _placeholder={{ color: 'rgba(255, 255, 255, 0.6)' }}
                  css={`
                    border-color: rgba(255, 255, 255, 0.6);
                  `}
                  focusBorderColor={'#009E95'}
                  placeholder={'Email Address'}
                  variant={'flushed'}
                />
              </VStack>
              <Text
                fontWeight={400}
                fontSize={'10px'}
                lineHeight={'15px'}
                letterSpacing={'0.5px'}
              >
                Your data is in good hands. We guarantee 100% privacy.
              </Text>
              <Button
                type={'submit'}
                fontWeight={600}
                fontSize={'16px'}
                lineHeight={'24px'}
                letterSpacing={'0.5px'}
                width={'106px'}
                height={'36px'}
                color={'#009E95'}
                disabled={state === 'submitting'}
                textTransform={'uppercase'}
                backgroundColor={'#fff'}
              >
                Sign Up
              </Button>
            </VStack>
          </VStack>
        </form>
      )}
      {state === 'submitted' && (
        <Box width={'100%'}>
          <Text>Thank you for subscribing!</Text>
        </Box>
      )}
    </Box>
  )
}

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
})

export default Form
