import React from 'react'
import { Box, Container, Img, VStack, Text, Heading } from '@chakra-ui/react'
import { NavigationBarHStack } from '../../../components'
import { Link } from 'gatsby'
import { GetStartedButton } from '../../../components'

const TitleSection: React.FC = () => {
  return (
    <Box
      width={'100%'}
      backgroundColor={'#111111'}
      backgroundImage={`url('${require('./bg.png')}')`}
      backgroundSize={'contain'}
      backgroundRepeat={'no-repeat'}
      backgroundPosition={'105% 118%'} /* TODO: Mobile positioning */
      height={[493, 493, 493, 507]}
    >
      <Container px={0} width={'82%'} maxWidth={1196} height={'100%'}>
        <VStack height={'100%'} spacing={['73px', '73px', '73px', '106px']}>
          <NavigationBarHStack>
            <Link to={'/'}>
              <Img
                src={require('../../../images/ursanex-logo.svg')}
                width={[105, 105, 105, 150]}
                height={[27, 27, 27, 39]}
                alt={'Ursanex Logo'}
              />
            </Link>
          </NavigationBarHStack>
          <VStack spacing={['30px', '30px', '30px', '60px']}>
            <VStack
              spacing={['18px', '18px', '18px', '20px']}
              textAlign={'center'}
            >
              <Heading
                fontWeight={700}
                color={'#ffffff'}
                fontSize={['32px', '32px', '32px', '64px']}
                lineHeight={['48px', '48px', '48px', '75px']}
              >
                Build healthcare better
              </Heading>
              <Text color={'#bdbdbd'}>
                Clinical insight for smarter innovation.
              </Text>
            </VStack>
            <GetStartedButton />
          </VStack>
        </VStack>
      </Container>
    </Box>
  )
}

export default TitleSection
