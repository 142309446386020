import React from 'react'
import { Button as ChakraButton, ButtonProps } from '@chakra-ui/react'

const Button: React.FC<ButtonProps> = (props) => {
  const { children } = props
  return (
    <ChakraButton
      backgroundColor={'#00FFF0'}
      color={['#000000', '#111111']}
      height={['36px', '48px']}
      fontSize={['14px', '16px']}
      fontWeight={600}
      textTransform={'uppercase'}
      letterSpacing={0.5}
      px={['20px', '38px']}
      {...props}
    >
      {children}
    </ChakraButton>
  )
}
export default Button
