import React from 'react'
import { Container, Stack, Box, Text, Img } from '@chakra-ui/react'

const Section5: React.FC = () => {
  return (
    <Container
      px={0}
      width={'82%'}
      maxWidth={1196}
      mx={'auto'}
      pt={['135px', '135px', '135px', '220px']}
      pb={['84px', '84px', '84px', '102px']}
    >
      <Stack direction={'row'}>
        <Box width={'50%'} display={['none', 'none', 'none', 'block']}>
          <Img
            src={require('./group-photo.jpg')}
            boxShadow={'30px 30px #E6F5F4'}
            width={400}
          />
        </Box>
        <Stack
          width={['100%', '100%', '100%', '50%']}
          lineHeight={'28px'}
          spacing={'45px'}
        >
          <Text>
            <Box as={'span'} fontWeight={600}>
              At Ursanex,
            </Box>{' '}
            we know health innovators have to overcome many obstacles to be
            successful. Unfortunately, we’ve seen time and again that a lack of
            access to clinical insight can completely derail new ventures,
            especially at the earliest stages when they need the most help. For
            folks without a clinical background or medical co-founder, trying to
            obtain proficiency in this area can feel impossible. We’re sick and
            tired of seeing brilliant engineers, entrepreneurs, and creatives
            working in healthtech held back because they don’t have the clinical
            support they need to reach their goals.
          </Text>
          <Text>
            If you’ve ever felt this way, know that we are building Ursanex for
            you. Schedule a call with us below, so that we can better understand
            your needs and scope out a custom roadmap for your product. We’ll
            leverage our network’s insights so that you can move your venture
            forward with confidence. There’s no reason to build your company
            alone –{' '}
            <Box as={'span'} fontWeight={600}>
              with Ursanex, your clinical backup has arrived
              <Box as={'span'} color={'#00FFF0'}>
                .
              </Box>
            </Box>
          </Text>
        </Stack>
      </Stack>
    </Container>
  )
}

export default Section5
