import React, { useState } from 'react'
import {
  Modal,
  ModalCloseButton,
  ModalBody,
  ModalOverlay,
  ModalContent,
  Stack,
  VStack,
  Heading,
  Text,
  Img,
  Box,
} from '@chakra-ui/react'

import Form from './Form'
import Button from '../Button'

interface IProps {
  isOpen: boolean
  finalFocusRef?: React.RefObject<any>
  onClose: () => void
}

const pdfName =
  'Think Like a Doctor - 3 Things Health Ventures Need to Know to Win Physician Buy-In'

const LeadGenModal: React.FC<IProps> = ({ isOpen, finalFocusRef, onClose }) => {
  const [state, setState] = useState<'initial' | 'submitted'>('initial')
  return (
    <Modal
      isOpen={isOpen}
      finalFocusRef={finalFocusRef}
      onClose={onClose}
      motionPreset={'slideInBottom'}
      isCentered={false} // TODO: Responsive centering on desktop
    >
      <ModalOverlay />
      <ModalCloseButton />
      <ModalContent
        width={state === 'initial' ? '82%' : 'auto'}
        maxWidth={'1006px'}
        borderRadius={0}
        mt={['68px', '68px', '68px', '12rem']}
      >
        <Stack direction={['column', 'column', 'column', 'row']} spacing={0}>
          {state === 'initial' && (
            <Box
              width={['100%', '100%', '100%', '526px']}
              backgroundPosition={'center'}
              backgroundRepeat={'no-repeat'}
              backgroundImage={`url('${require('./think-like-a-doctor-pdf-cover-image.png')}')`}
              backgroundSize={'contain'}
              backgroundColor={'#171717'}
            >
              <Img
                display={['block', 'block', 'block', 'none']}
                width={'100%'}
                src={require('./think-like-a-doctor-pdf-cover-image.png')}
                maxHeight={['100%', '100%', '100%', 'auto']}
              />
            </Box>
          )}

          <ModalBody
            flexGrow={1}
            minWidth={['initial', 'initial', 'initial', '480px']}
            width={['100%', '100%', '100%', '50%']}
            pl={['18px', '18px', '18px', '46px']}
            pr={['19px', '19px', '19px', '46px']}
            pt={['20px', '20px', '20px', '27px']}
            pb={['21px', '21px', '21px', '27px']}
            backgroundColor={'white'}
          >
            {state === 'initial' && (
              <VStack width={'100%'} spacing={'17px'}>
                <VStack width={'100%'} spacing={'7px'} align={'flex-start'}>
                  <Heading
                    fontWeight={700}
                    fontSize={'24px'}
                    lineHeight={'36px'}
                  >
                    I’m ready for my clinical insight
                  </Heading>
                  <Text fontSize={'16px'} lineHeight={'24px'}>
                    Give me access to “{pdfName}” now
                  </Text>
                </VStack>
                <Form
                  portalId={'8163814'}
                  formGuid={'f7f24530-4e16-4b17-adc0-451ceb49cb85'}
                  onSubmitted={() => {
                    setState('submitted')
                  }}
                />
              </VStack>
            )}
            {state === 'submitted' && (
              <VStack width={'100%'} pt={'28px'} px={'44px'} pb={'19px'}>
                <VStack width={'100%'} spacing={'44px'}>
                  <Heading
                    fontSize={'24px'}
                    lineHeight={'36px'}
                    width={'100%'}
                    maxWidth={'300px'}
                    textAlign={'center'}
                  >
                    Thank you, enjoy your PDF.
                  </Heading>
                  <Button
                    onClick={() => {
                      onClose()
                    }}
                  >
                    Close
                  </Button>
                </VStack>
              </VStack>
            )}
          </ModalBody>
        </Stack>
      </ModalContent>
    </Modal>
  )
}

export default LeadGenModal
