import React, { useState } from 'react'
import { Link } from 'gatsby'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  Box,
  Stack,
  VStack,
  Input,
  Select,
  Textarea,
  Text,
} from '@chakra-ui/react'
import * as yup from 'yup'
import { Button } from '../../components'

interface IProps {
  portalId: string
  formGuid: string
  onSubmitted: () => void
}

const _placeholder = {
  color: '#989898',
  fontSize: '16px',
  lineHeight: '24px',
  opacity: 1,
  fontWeight: 400,
}

enum CompanyProfile {
  healthVenture = 'Health Venture',
  acceleratorOrIncubator = 'Accelerator/Incubator',
  academic = 'University/Academic',
  investor = 'Investor',
}

const companyProfileOptions = [
  {
    value: 'health-venture',
    label: 'Health Venture',
  },
  { value: 'accelerator-or-incubator', label: 'Accelerator/Incubator' },
  { value: 'academic', label: 'University/Academic' },
  { value: 'investor', label: 'Investor' },
]

const Form: React.FC<IProps> = ({ portalId, formGuid, onSubmitted }) => {
  const [state, setState] = useState<'ready' | 'submitting'>('ready')
  const { register, handleSubmit, watch, errors } = useForm({
    resolver: yupResolver(schema),
  })
  console.log(watch('companyProfile'))
  const onSubmit = async ({
    firstName,
    lastName,
    email,
    company,
    companyProfile,
    jobTitle,
    notes,
  }) => {
    setState('submitting')
    try {
      const response = await fetch(
        // TODO: Add additional context information (i.e., url user was one)
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fields: [
              {
                name: 'firstname',
                value: firstName,
              },
              {
                name: 'lastname',
                value: lastName,
              },
              {
                name: 'email',
                value: email,
              },
              {
                name: 'company',
                value: company,
              },
              {
                name: 'company_profile',
                value: companyProfile,
              },
              {
                name: 'jobtitle',
                value: jobTitle,
              },
              {
                name: 'notes',
                value: notes,
              },
            ],
          }),
        },
      )
      console.log(await response.json())
      if (response.ok) {
        onSubmitted()
        return
      }
      setState('ready')
      // TODO: Handle returned errors
    } catch (error) {
      setState('ready')
      alert('Could not submit form')
      console.error(error)
      //
    }
  }
  return (
    <Box
      pt={'38px'}
      pr={['30px', '46px']}
      pb={['34px', '40px']}
      pl={['30px', '46px']}
      backgroundColor={'white'}
      color={'#000000'}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={'6px'} width={'100%'}>
          <VStack spacing={'24px'} width={'100%'}>
            <VStack spacing={'16px'} width={'100%'}>
              <Stack direction={['row']} spacing={['4px']} width={'100%'}>
                <Input
                  name={'firstName'}
                  ref={register}
                  width={'50%'}
                  focusBorderColor={'#009E95'}
                  placeholder={'First Name*'}
                  _placeholder={_placeholder}
                  variant={'flushed'}
                  isRequired
                  isInvalid={!!errors.firstName}
                  isDisabled={state === 'submitting'}
                />
                <Input
                  name={'lastName'}
                  ref={register}
                  width={'50%'}
                  focusBorderColor={'#009E95'}
                  placeholder={'Last Name*'}
                  _placeholder={_placeholder}
                  variant={'flushed'}
                  isRequired
                  isInvalid={!!errors.lastName}
                  isDisabled={state === 'submitting'}
                />
              </Stack>
              <Input
                name={'email'}
                type={'email'}
                ref={register}
                placeholder={'Email Address*'}
                focusBorderColor={'#009E95'}
                _placeholder={_placeholder}
                variant={'flushed'}
                isRequired
                isInvalid={!!errors.email}
                isDisabled={state === 'submitting'}
              />
              <Stack direction={['row']} spacing={['4px']} width={'100%'}>
                <Input
                  name={'company'}
                  ref={register}
                  width={'50%'}
                  placeholder={'Company*'}
                  focusBorderColor={'#009E95'}
                  _placeholder={_placeholder}
                  variant={'flushed'}
                  isRequired
                  isInvalid={!!errors.company}
                  isDisabled={state === 'submitting'}
                />
                <Input
                  name={'jobTitle'}
                  ref={register}
                  width={'50%'}
                  placeholder={'Title*'}
                  focusBorderColor={'#009E95'}
                  _placeholder={_placeholder}
                  variant={'flushed'}
                  isRequired
                  isInvalid={!!errors.jobTitle}
                  isDisabled={state === 'submitting'}
                />
              </Stack>
              <Select
                name={'companyProfile'}
                ref={register}
                placeholder={'Company Profile*'}
                variant={'flushed'}
                focusBorderColor={'#009E95'}
                isRequired
                color={!watch('companyProfile') && '#989898'}
                isInvalid={!!errors.companyProfile}
                isDisabled={state === 'submitting'}
              >
                {companyProfileOptions.map(({ label, value }, index) => (
                  <option key={index} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </VStack>
            <Textarea
              name={'notes'}
              ref={register}
              rows={4}
              focusBorderColor={'#009E95'}
              placeholder={
                'Please describe your project(s) and any relevant details you’d like to discuss, so we can determine how to best collaborate*'
              }
              isRequired
              isDisabled={state === 'submitting'}
              isInvalid={!!errors.notes}
              borderRadius={0}
              border={0}
              backgroundColor={'rgba(186, 186, 186, 0.2)'}
              _placeholder={{
                ..._placeholder,
                fontSize: '12px',
                lineHeight: '20px',
              }}
            />
          </VStack>
          <VStack spacing={'20px'} width={'100%'}>
            <Text
              fontSize={'9px'}
              lineHeight={'11px'}
              letterSpacing={'0.5px'}
              color={'#989898'}
            >
              Your privacy is important to us. Ursanex uses the information you
              provide to contact you about our relevant content, products, and
              services. You may unsubscribe from these communications at any
              time. For more information, please review our{' '}
              <Link
                to={
                  'https://www.termsfeed.com/live/2a802abe-9618-4360-99dd-5fc0e509072d'
                }
                target={'_blank'}
              >
                <Box
                  as={'span'}
                  fontWeight={600}
                  textDecoration={'underline'}
                  display={'inline'}
                >
                  Privacy Policy
                </Box>
              </Link>
              .
            </Text>
            <Button
              type={'submit'}
              disabled={state === 'submitting'}
              alignSelf={'flex-start'}
            >
              {state === 'submitting' ? 'Submitting...' : 'Submit'}
            </Button>
          </VStack>
        </VStack>
      </form>
    </Box>
  )
}

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  company: yup.string().required(),
  jobTitle: yup.string().required(),
  companyProfile: yup.string().required(), // <-- TODO: Validate values with enum
  notes: yup.string().required(),
})

export default Form
