import React from 'react'
import {
  Box,
  Heading,
  Stack,
  VStack,
  Container,
  List,
  ListItem,
} from '@chakra-ui/react'

import StepCard from './StepCard'
import { GetStartedButton } from '../../../components'

// TODO: Update color of first card
const steps: {
  heading: string
  body: string
  colors?: { background: string; numberCircleBackground: string }
}[] = [
  {
    heading: 'Get Started',
    body: 'Book your free consultation and let us know what you’re working on',
    colors: {
      background: '#009E95',
      numberCircleBackground: '#126F69',
    },
  },
  {
    heading: 'Receive your high-impact solution roadmap',
    body: 'Let us outline a custom deliverable tailored to your venture',
  },
  {
    heading: 'Let us recruit your physician partners',
    body:
      'We’ll build and manage a team of physicians best-suited to validate your product',
  },
  {
    heading: 'Move forward with confidence',
    body: 'Receive key clinical insights so you can grow with peace of mind',
  },
]

const Section4: React.FC = () => {
  return (
    <Box backgroundColor={'#E6F5F4'} color={'#000'} position={'relative'}>
      <Container
        px={0}
        width={'82%'}
        maxWidth={1196}
        mx={'auto'}
        pt={['95px', '95px', '95px', '153px']}
        pb={['70px', '70px', '70px', '75px']}
      >
        <Stack
          direction={['column', 'column', 'column', 'row']}
          spacing={['20px', '20px', '20px', '']}
          justify={['', '', '', 'space-between']}
        >
          <VStack
            alignSelf={'center'}
            maxWidth={544}
            align={'flex-start'}
            spacing={['', '', '', '40px']}
          >
            <Heading
              fontWeight={700}
              fontSize={['32px', '32px', '32px', '64px']}
              lineHeight={['45px', '45px', '45px', '80px']}
            >
              Your plan to grow your health venture with confidence
            </Heading>
            <GetStartedButton display={['none', 'none', 'none', 'block']} />
          </VStack>
          <VStack spacing={['40px', '40px', '40px', '']}>
            <Box position={'relative'} zIndex={1}>
              <Box
                position={'absolute'}
                width={'3px'}
                height={'100%'}
                zIndex={-1}
                backgroundColor={'#00FFF0'}
                top={0}
                left={['6.5%', '6.5%', '6.5%', '9%']}
              />
              <List spacing={['20px', '20px', '20px', '34px']} maxWidth={540}>
                {steps.map(({ heading, body, colors }, index) => (
                  <ListItem key={index}>
                    <StepCard
                      number={index + 1}
                      heading={heading}
                      body={body}
                      colors={colors}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
            <GetStartedButton display={['block', 'block', 'block', 'none']} />
          </VStack>
        </Stack>
      </Container>
      <Box
        position={'absolute'}
        bottom={['-75px', '-75px', '-75px', '-150px']}
        borderRight={'100vw solid transparent'}
        borderTopColor={'#E6F5F4'}
        borderTopStyle={'solid'}
        borderTopWidth={['75px', '75px', '75px', '150px']}
      />
    </Box>
  )
}

export default Section4
