import React from 'react'
import { Box, Container, Heading, Text, VStack } from '@chakra-ui/react'
import { GetStartedButton } from '../../../components'
const Section4: React.FC = () => {
  return (
    <Box backgroundColor={'#E6F5F4'} py={['110px', '110px', '110px', '90px']}>
      <Container>
        <VStack spacing={['32px', '32px', '32px', '50px']}>
          <VStack
            textAlign={'center'}
            spacing={['32px', '30px', '30px', '30px']}
          >
            <Heading textTransform={'uppercase'}>
              Start innovating better, together
            </Heading>
            <Text>
              Find out how Ursanex can help your venture become a leader in
              health innovation.
            </Text>
          </VStack>
          <GetStartedButton />
        </VStack>
      </Container>
    </Box>
  )
}

export default Section4
