import React from 'react'
import { Box, Heading, Text, VStack, Container } from '@chakra-ui/react'

const Section1: React.FC = () => {
  return (
    <Box py={['60px', '60px', '60px', '100px']}>
      <Container px={0} width={'82%'} maxWidth={'760px'} margin={'auto'}>
        <VStack spacing={['20px', '20px', '20px', '30px']}>
          <Heading fontSize={'24px'} lineHeight={'36px'} fontWeight={700}>
            About Us
          </Heading>
          <Text
            fontSize={['16px', '16px', '16px', '18px']}
            lineHeight={['24px', '24px', '24px', '27px']}
          >
            At Ursanex, we know that building new health tech is a team effort.
            We’re bringing together a community of clinical experts that will
            help the next generation of health ventures, ideate, validate, and
            scale.
          </Text>
          <Text
            fontSize={['16px', '16px', '16px', '18px']}
            lineHeight={['24px', '24px', '24px', '27px']}
          >
            We’re a group of people intensely passionate about driving health
            innovation forward. We want to help innovators everywhere find the
            expertise they need to build with confidence.
          </Text>
          <Text
            fontSize={['16px', '16px', '16px', '18px']}
            lineHeight={['24px', '24px', '24px', '27px']}
          >
            Why? Because we know innovation is lagging due to a disconnected
            healthcare landscape where entrepreneurs, investors, and physicians
            are currently operating in distinct siloes. Many health ventures
            lack easy access to the clinical insights they need to succeed,
            while many physicians lack the legal, financial, and networking
            tools they require to engage these ventures and, as such, are often
            relegated to the sidelines.
          </Text>
          <Text
            fontSize={['16px', '16px', '16px', '18px']}
            lineHeight={['24px', '24px', '24px', '27px']}
          >
            We’re here to bridge the gap, by unlocking clinical insights for
            health ventures while delivering the tools to enable physician
            participation on the front lines of innovation. Together, we can
            create the healthcare of tomorrow.
          </Text>
        </VStack>
      </Container>
    </Box>
  )
}

export default Section1
