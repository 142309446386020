import React from 'react'
import { navigate } from 'gatsby'

import { Button } from '.'

const GetStartedButton = (props) => {
  return (
    <Button
      {...props}
      onClick={() => {
        navigate('/get-started')
      }}
    >
      Get Started
    </Button>
  )
}

export default GetStartedButton
