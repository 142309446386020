import React from 'react'
import {
  Container,
  VStack,
  Heading,
  SimpleGrid,
  GridItem,
  HStack,
  Text,
  Img,
} from '@chakra-ui/react'

const listItems = [
  {
    icon: {
      src: require('./icon-1.svg'),
    },
    title: 'Faster, doctor-approved innovation',
    body:
      'We strive to deliver quality due diligence with efficiency. By providing accelerated clinical validation to health ventures, we endeavor to get teams moving quickly while honoring our oath as doctors to, “first, do no harm.”',
  },
  {
    icon: {
      src: require('./icon-2.svg'),
    },
    title: 'Data-driven disruption, with integrity',
    body:
      'Our team delivers data-driven solutions built with integrity. We create our deliverables by bringing together relevant clinical research and the opinions of experienced clinicians. We pursue projects where we believe we can add unique value. Our focus on quality translates to your building products with real potential to disrupt healthcare.',
  },
  {
    icon: {
      src: require('./icon-3.svg'),
    },
    title: 'A health innovation community, without limits',
    body:
      'We believe cross-pollination and collaboration are crucial in health innovation, and shouldn’t be limited by location or access. Through our platform, we seek to establish a strong health innovation ecosystem that can support all types of health ventures - no matter where you call home.',
  },
  {
    icon: {
      src: require('./icon-4.svg'),
    },
    title: 'Human first. Period.',
    body:
      'There is no greater measure of success for health tech than the positive impact it has on patients, healthcare workers, and society. For us, this is the most inspiring part of working with health ventures. We’re here to help you build high-impact solutions to make care delivery more efficient, improve patient outcomes, and save lives.',
  },
]

const Section2: React.FC = () => {
  return (
    <Container
      px={0}
      width={'82%'}
      maxWidth={1196}
      margin={'auto'}
      borderTop={'3px solid #F1F1F1'}
      borderBottom={'3px solid #F1F1F1'}
      py={['60px', '60px', '60px', '100px']}
    >
      <VStack spacing={['20px', '20px', '20px', '30px']}>
        <Heading fontSize={'24px'} lineHeight={'36px'} fontWeight={700}>
          Our Values
        </Heading>
        <SimpleGrid
          rowGap={['20px', '20px', '20px', '92px']}
          columns={[1, 1, 1, 2]}
          columnGap={['20px', '20px', '20px', '43px']}
        >
          {listItems.map(({ icon, title, body }, index) => (
            <GridItem key={index} maxWidth={'522px'}>
              <HStack
                align={'flex-start'}
                spacing={['10px', '10px', '10px', '30px']}
              >
                <Img src={icon.src} alt={'Icon'} maxHeight={'52px'} />

                <VStack align={'flex-start'} spacing={'6px'}>
                  <Text
                    fontWeight={700}
                    fontSize={['16px', '16px', '16px', '18px']}
                    lineHeight={['24px', '24px', '24px', '27px']}
                  >
                    {title}
                  </Text>
                  <Text>{body}</Text>
                </VStack>
              </HStack>
            </GridItem>
          ))}
        </SimpleGrid>
      </VStack>
    </Container>
  )
}

export default Section2
