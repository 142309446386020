import React, { useState } from 'react'
import { Input, Stack, VStack, Text, Box, Link } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '..'

interface IProps {
  portalId: string
  formGuid: string
  onSubmitted: () => void
}

function triggerPdfDownload() {
  const a = document.createElement('a')
  a.href = require('./ursanex-think-like-a-doctor.pdf')
  a.download = 'ursanex-think-like-a-doctor.pdf'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

const Form: React.FC<IProps> = ({ portalId, formGuid, onSubmitted }) => {
  const [state, setState] = useState<'ready' | 'submitting'>('ready')
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = async ({ firstName, lastName, email }) => {
    setState('submitting')

    try {
      const response = await fetch(
        // TODO: Add additional context information (i.e., url user was one)
        `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            fields: [
              {
                name: 'firstname',
                value: firstName,
              },
              {
                name: 'lastname',
                value: lastName,
              },
              {
                name: 'email',
                value: email,
              },
            ],
          }),
        },
      )
      if (response.ok) {
        triggerPdfDownload()
        onSubmitted()
        return
      }
      setState('ready')
      // TODO: Handle returned errors
    } catch (error) {
      setState('ready')
      alert('Could not submit form')
      console.error(error)
      //
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={'10px'} width={'100%'}>
        <VStack width={'100%'} spacing={'16px'}>
          <Stack
            direction={['column', 'column', 'column', 'row']}
            width={'100%'}
            spacing={['16px', '16px', '16px', '4px']}
          >
            <Input
              name={'firstName'}
              ref={register}
              placeholder={'First Name*'}
              width={['100%', '100%', '100%', '50%']}
              isInvalid={!!errors.firstName}
              disabled={state === 'submitting'}
              isRequired
              focusBorderColor={'#009E95'}
              borderColor={'#989898'}
              _placeholder={{
                color: '#989898',
                fontSize: '16px',
                lineHeight: '24px',
              }}
              variant={'flushed'}
            />
            <Input
              name={'lastName'}
              ref={register}
              placeholder={'Last Name*'}
              width={['100%', '100%', '100%', '50%']}
              isInvalid={!!errors.lastName}
              disabled={state === 'submitting'}
              isRequired
              focusBorderColor={'#009E95'}
              borderColor={'#989898'}
              _placeholder={{
                color: '#989898',
                fontSize: '16px',
                lineHeight: '24px',
              }}
              variant={'flushed'}
            />
          </Stack>
          <Input
            name={'email'}
            ref={register}
            placeholder={'Email Address*'}
            isInvalid={!!errors.email}
            disabled={state === 'submitting'}
            isRequired
            type={'email'}
            focusBorderColor={'#009E95'}
            borderColor={'#989898'}
            _placeholder={{
              color: '#989898',
              fontSize: '16px',
              lineHeight: '24px',
            }}
            variant={'flushed'}
          />
        </VStack>
        <VStack width={'100%'} spacing={'20px'} align={'flex-start'}>
          <Text
            fontSize={'9px'}
            lineHeight={'11px'}
            letterSpacing={'0.5px'}
            color={'#989898'}
          >
            Your privacy is important to us. Ursanex uses the information you
            provide to contact you about our relevant content, products, and
            services. You may unsubscribe from these communications at any time.
            For more information, please review our{' '}
            <Link
              href={
                'https://www.termsfeed.com/live/2a802abe-9618-4360-99dd-5fc0e509072d'
              }
              target={'_blank'}
            >
              <Box
                as={'span'}
                fontWeight={600}
                textDecoration={'underline'}
                display={'inline'}
              >
                Privacy Policy
              </Box>
            </Link>
            .
          </Text>
          <Button type={'submit'} disabled={state === 'submitting'}>
            {/*state === 'submitting' ? 'Submitting...' : 'Submit'*/}
            Download
          </Button>
        </VStack>
      </VStack>
    </form>
  )
}

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
})

export default Form
