import React, { useState } from 'react'
import {
  Box,
  Img,
  Container,
  VStack,
  Stack,
  Heading,
  Text,
  HStack,
  Link,
} from '@chakra-ui/react'
import { Link as GatsbyLink, navigate } from 'gatsby'
import { Helmet } from 'react-helmet'
import { Button, NavigationBarHStack } from '../../components'
import Form from './Form'

const Footer: React.FC<{ justify: string[] | string }> = ({ justify }) => (
  <HStack
    zIndex={4}
    pb={['30px', '30px', '30px', '32px']}
    justify={justify}
    width={'100%'}
    spacing={['12px', '12px', '12px', '22px']}
    letterSpacing={'0.5px'}
    fontSize={['10px', '10px', '10px', '12px']}
    lineHeight={['15px', '15px', '15px', '18px']}
  >
    <Text>© 2021 Ursanex</Text>
    <Link
      href={
        'https://www.termsfeed.com/live/2a802abe-9618-4360-99dd-5fc0e509072d'
      }
      target={'_blank'}
    >
      Privacy Policy
    </Link>
    <Link
      href={
        'https://www.termsfeed.com/live/7734578f-cfd3-4514-9a79-8786e4ceea1d'
      }
      target={'_blank'}
    >
      Terms &amp; Conditions
    </Link>
  </HStack>
)

const GetStarted: React.FC = () => {
  const [state, setState] = useState<'ready' | 'finished'>('ready')
  return (
    <>
      <Helmet>
        <style>{`body { background-color: #111 }`}</style>
      </Helmet>
      <Box backgroundColor={'#111'} color={'#bdbdbd'} width={'100%'}>
        <Container px={0} width={'82%'} height={'100%'} maxWidth={1196}>
          <VStack
            width={'100%'}
            spacing={['50px', '50px', '50px', '100px']}
            zIndex={2}
          >
            <NavigationBarHStack>
              <GatsbyLink to={'/'}>
                <Img
                  src={require('../../images/ursanex-logo.svg')}
                  width={[105, 105, 105, 150]}
                  height={[27, 27, 27, 39]}
                  zIndex={2}
                  alt={'Ursanex Logo'}
                />
              </GatsbyLink>
            </NavigationBarHStack>

            {state === 'ready' && (
              <VStack width={'100%'} spacing={'20px'}>
                <Stack
                  direction={['column', 'column', 'column', 'row']}
                  width={'100%'}
                  justify={'space-between'}
                  alignItems={'center'}
                  spacing={['40px', '40px', '40px', '40px']}
                  zIndex={2}
                >
                  <VStack
                    spacing={['20px', '20px', '20px', '30px']}
                    maxWidth={'516px'}
                  >
                    <Heading
                      color={'white'}
                      fontWeight={700}
                      fontSize={['32px', '32px', '32px', '48px']}
                      lineHeight={['42px', '42px', '42px', '55px']}
                    >
                      Your Ursanex Journey Starts Now
                    </Heading>
                    <Text
                      fontWeight={400}
                      fontSize={'16px'}
                      lineHeight={'24px'}
                    >
                      Book your free consultation and get the clinical insight
                      you need.
                    </Text>
                  </VStack>
                  <VStack maxWidth={'480px'}>
                    <Form
                      portalId={'8163814'}
                      formGuid={'295f1422-d880-4def-afd3-dae77a0fabd4'}
                      onSubmitted={() => {
                        setState('finished')
                      }}
                    />
                  </VStack>
                </Stack>
                <Footer justify={['center', 'center', 'center', 'flex-end']} />
              </VStack>
            )}
            {state === 'finished' && (
              <VStack
                width={'100%'}
                height={['60vh', '60vh', '60vh', '80vh']}
                marginTop={[
                  '70px !important',
                  '70px !important',
                  '70px !important',
                  '0px !important',
                ]}
                justifyContent={[
                  'flex-start',
                  'flex-start',
                  'flex-start',
                  'center',
                ]}
                zIndex={2}
              >
                <VStack spacing={'40px'}>
                  <VStack spacing={['50px', '50px', '50px', '79px']}>
                    <Heading
                      color={'white'}
                      maxWidth={'830px'}
                      fontWeight={700}
                      fontSize={['32px', '32px', '32px', '48px']}
                      lineHeight={['42px', '42px', '42px', '55px']}
                      textAlign={'center'}
                      px={'16px'}
                    >
                      Thank you, we’ll be in touch soon!
                    </Heading>
                    <Button
                      onClick={() => {
                        navigate('/')
                      }}
                    >
                      Back To Homepage
                    </Button>
                  </VStack>
                  <Footer justify={'center'} />
                </VStack>
              </VStack>
            )}
          </VStack>
        </Container>

        <Box
          position={'fixed'}
          left={-113}
          bottom={-73}
          backgroundSize={'contain'}
          backgroundPosition={'center'}
          backgroundRepeat={'no-repeat'}
          backgroundImage={`url('${require('./design-element.svg')}')`}
          width={503}
          height={480}
        />
      </Box>
    </>
  )
}

export default GetStarted
