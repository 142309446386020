import React from 'react'
import {
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  Stack,
  VStack,
  Heading,
  Text,
  Img,
  Box,
  ModalCloseButton,
} from '@chakra-ui/react'
import { Button } from '../../../components'

import teamMembers from './teamMembers'

interface IProps {
  isOpen: boolean
  teamMemberId: number
  finalFocusRef?: React.RefObject<any>
  onClose: () => void
}

const BiographyModal: React.FC<IProps> = ({
  isOpen,
  teamMemberId,
  finalFocusRef,
  onClose,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={'5xl'}
      finalFocusRef={finalFocusRef}
      isCentered={false} // TODO: Responsive centering on desktop
    >
      <ModalOverlay />
      <ModalContent
        borderRadius={0}
        width={'82%'}
        maxWidth={'948px'}
        mx={'auto'}
      >
        <ModalBody
          py={['28px', '28px', '28px', '70px']}
          px={['18px', '18px', '18px', '63px']}
        >
          <ModalCloseButton display={['none', 'none', 'none', 'block']} />
          <Stack
            width={'100%'}
            direction={['column', 'column', 'column', 'row']}
            spacing={['22px', '22px', '22px', '52px']}
          >
            <Box
              width={['100%', '100%', '100%', '340px']}
              height={['auto', 'auto', 'auto', '340px']}
            >
              <Img src={teamMembers[teamMemberId]?.photoSrc} alt={'Photo'} />
            </Box>
            <Box width={['100%', '100%', '100%', '430px']}>
              <VStack spacing={'20px'} width={'100%'}>
                <VStack spacing={'7px'} align={'flex-start'} width={'100%'}>
                  <Heading
                    fontWeight={700}
                    fontSize={['24px', '24px', '24px', '24px']}
                    lineHeight={['36px', '36px', '36px', '36px']}
                  >
                    {teamMembers[teamMemberId]?.heading}
                  </Heading>
                  <Text
                    fontSize={['16px', '16px', '16px', '16px']}
                    lineHeight={['24px', '24px', '24px', '24px']}
                    color={'#33333'}
                  >
                    {teamMembers[teamMemberId]?.bio}
                  </Text>
                </VStack>
                <Button
                  onClick={onClose}
                  display={['block', 'block', 'block', 'none']}
                >
                  Close
                </Button>
              </VStack>
            </Box>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
export default BiographyModal
