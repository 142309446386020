import React from 'react'
import {
  Container,
  Heading,
  Text,
  Stack,
  Link,
  HStack,
  VStack,
  Img,
  Box,
} from '@chakra-ui/react'
import { Link as GatsbyLink } from 'gatsby'
import { GetStartedButton } from '../../../components'
import { NavigationBarHStack } from '../../../components'

const mobileBackground = `
  radial-gradient(54.93% 36.27% at 38.13% 115.16%, #5BDBD3 0%, rgba(29, 196, 156, 0) 100%), 
  radial-gradient(34.08% 55.95% at -1.94% 112.02%, #5BDBD3 0%, rgba(0, 0, 0, 0) 100%), 
  radial-gradient(95.67% 48.44% at 146.4% 58.86%, #00FFF0 0%, #111111 100%);
`

const desktopBackground = `
  radial-gradient(50.31% 50.31% at 65% 114.72%, #5BDBD3 0%, rgba(29, 196, 156, 0) 100%), 
  radial-gradient(34.08% 55.95% at -1.94% 112.02%, #5BDBD3 0%, rgba(0, 0, 0, 0) 100%), 
  radial-gradient(31.93% 65.3% at 109.86% 73.25%, #00FFF0 0%, #111111 100%);
`
const TitleSection = () => {
  return (
    <Box
      width={'100%'}
      height={[729, 729, 729, 905]}
      background={[mobileBackground, desktopBackground]}
    >
      <Container px={0} width={'82%'} maxWidth={1196} height={'100%'}>
        <VStack height={'100%'} spacing={['73px', '167px']}>
          <NavigationBarHStack>
            <Img
              src={require('../../../images/ursanex-logo.svg')}
              width={[105, 105, 105, 150]}
              height={[27, 27, 27, 39]}
              alt={'Ursanex Logo'}
            />
            <HStack spacing={'32px'}>
              <Link
                as={GatsbyLink}
                to={'/about-us'}
                color={'#fff'}
                fontWeight={600}
                marginTop={['8px', '8px', '8px', '0']} // dirty alignment fix to center with logo
                textTransform={'uppercase'}
                fontSize={['12px', '12px', '12px', '16px']}
                lineHeight={'24px'}
              >
                About Us
              </Link>
              <GetStartedButton display={['none', 'none', 'none', 'block']} />
            </HStack>
          </NavigationBarHStack>
          <VStack
            maxWidth={933}
            height={'100%'}
            spacing={['60px', '60px', '60px', '66px']}
            align={'flex-start'}
          >
            <Stack spacing={['30px', '30px', '30px', '20px']}>
              <Heading
                fontWeight={700}
                fontSize={['32px', '32px', '32px', '64px']}
                lineHeight={['42px', '42px', '42px', '75px']}
                color={'white'}
              >
                On-Demand Clinical Insight to Help Your Health Venture Succeed
              </Heading>
              <Text fontSize={'16px'} lineHeight={'24px'} color={'#bdbdbd'}>
                Stop guessing when it comes to your health tech. Start your
                Ursanex journey, and change healthcare today.
              </Text>
            </Stack>
            <GetStartedButton />
          </VStack>
        </VStack>
      </Container>
    </Box>
  )
}

export default TitleSection
