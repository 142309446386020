import React from 'react'
import { HStack } from '@chakra-ui/react'
const NavigationBarHStack = ({ children }) => {
  return (
    <HStack
      width={'100%'}
      minHeight={['76px', '90px']}
      maxHeight={['76px', '90px']}
      height={['76px', '90px']}
      justify={'space-between'}
    >
      {children}
    </HStack>
  )
}

export default NavigationBarHStack
