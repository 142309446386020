// https://www.gatsbyjs.org/docs/add-seo-component/
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'

interface ISEOProps {
  title?: string
  withoutTitleTemplate?: boolean
  titleTemplate?: string
  description?: string
  image?: string
  article?: string
}

const SEO: React.FC<ISEOProps> = ({
  title,
  withoutTitleTemplate,
  description,
  image,
  article,
}) => {
  const { site } = useStaticQuery(query)
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    // twitterUsername,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    titleTemplate,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    // url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet
      title={seo.title}
      titleTemplate={!withoutTitleTemplate ? '' : titleTemplate}
    >
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {/* seo.url && <meta property="og:url" content={seo.url} />*/}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {/*twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername} />
      )*/}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        # twitterUsername
      }
    }
  }
`

export default SEO
