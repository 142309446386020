import React from 'react'
import {
  Box,
  Heading,
  Img,
  VStack,
  Container,
  Text,
  List,
  ListItem,
} from '@chakra-ui/react'

import { GetStartedButton } from '../../../components'

const listItems = [
  'Losing investor dollars',
  'Wasting months TO years of development time',
  'Creating a technology physicians won’t adopt',
  'Developing a product that’s not commercially viable',
  'Mistaking good science for good business',
  'Leaving behind better-use cases for their tech',
]

const Section1: React.FC = () => {
  return (
    <Box backgroundColor={'#111111'} color={'#ffffff'}>
      <Container px={0} width={'82%'} maxWidth={'1156px'}>
        <VStack
          pt={['80px', '80px', '80px', '70px']}
          spacing={['80px', '80px', '80px', '76px']}
        >
          <VStack mx={'auto'} spacing={['50px', '50px', '50px', '70px']}>
            <Heading
              fontWeight={500}
              fontSize={['32px', '32px', '32px', '48px']}
              lineHeight={['48px', '48px', '48px', '72px']}
              textAlign={'center'}
              maxWidth={755}
              mx={'auto'}
            >
              Are you struggling to find the clinical insight you need?
            </Heading>
            <VStack
              mx={'auto'}
              width={'100%'}
              spacing={['81px', '81px', '81px', '74px']}
            >
              <VStack
                mx={'auto'}
                maxWidth={1156}
                spacing={['33px', '33px', '33px', '36px']}
              >
                <Heading
                  fontWeight={400}
                  fontSize={['18px', '18px', '18px', '24px']}
                  lineHeight={['27px', '27px', '27px', '36px']}
                  textAlign={'center'}
                >
                  For many health ventures, a lack of accessible medical
                  expertise puts their team at risk of:
                </Heading>
                <List
                  fontWeight={500}
                  fontSize={'16px'}
                  lineHeight={'24px'}
                  textTransform={'uppercase'}
                  color={'#bdbdbd'}
                  css={`
                    column-count: 1;
                    column-gap: 0px;
                    @media screen and (min-width: 767px) {
                      column-count: 2;
                      column-gap: 10px; // Note: this property has very limited browser support
                    }
                  `}
                >
                  {listItems.map((listItem, index) => (
                    <ListItem
                      key={index}
                      borderLeft={'2px solid #00FFF0'}
                      pl={['11px', '11px', '11px', '19px']}
                      pb={[0, 0, 0, '26px']}
                      mb={['32px', '32px', '32px', 0]}
                    >
                      {listItem}
                    </ListItem>
                  ))}
                </List>
              </VStack>
              <VStack spacing={['30px', '30px', '30px', '20px']}>
                <Text
                  mt={['-32px', '-32px', '-32px', 0]}
                  fontWeight={500}
                  fontSize={'16px'}
                  lineHeight={'24px'}
                  textAlign={'center'}
                >
                  In healthcare, second opinions save ventures. Get the clinical
                  insight you need.
                </Text>
                <GetStartedButton />
              </VStack>
            </VStack>
          </VStack>
          <Box maxWidth={['82%', '82%', '82%', '635px']}>
            <Img
              src={require('./dashboard-concept.png')}
              alt={'Ursanex Dashboard'}
            />
          </Box>
        </VStack>
      </Container>
    </Box>
  )
}

export default Section1
