import React from 'react'
import { createIcon } from '@chakra-ui/react'

const LinkedIn = createIcon({
  displayName: 'LinkedInIcon',
  viewBox: '0 0 27 27',
  path: (
    <path
      d="M13.5 2.61292C7.48814 2.61292 2.6129 7.48815 2.6129 13.5C2.6129 19.5119 7.48814 24.3871 13.5 24.3871C19.5119 24.3871 24.3871 19.5119 24.3871 13.5C24.3871 7.48815 19.5119 2.61292 13.5 2.61292ZM10.3363 19.0711H7.68483V11.094H10.3363V19.0711ZM9.01067 10.0048H8.99339C8.10363 10.0048 7.52818 9.39227 7.52818 8.62677C7.52818 7.84399 8.12124 7.24844 9.02828 7.24844C9.93531 7.24844 10.4935 7.84399 10.5108 8.62677C10.5108 9.39227 9.93531 10.0048 9.01067 10.0048ZM19.8971 19.0711H17.2459V14.8036C17.2459 13.7311 16.862 12.9996 15.9026 12.9996C15.1702 12.9996 14.734 13.493 14.5423 13.9693C14.4722 14.1398 14.455 14.378 14.455 14.6164V19.0711H11.8037C11.8037 19.0711 11.8384 11.8424 11.8037 11.094H14.455V12.2235C14.8074 11.68 15.4378 10.9068 16.8446 10.9068C18.589 10.9068 19.8971 12.0469 19.8971 14.4971V19.0711Z"
      fill="black"
    />
  ),
})

export default LinkedIn
