import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import {
  Box,
  Container,
  Img,
  VStack,
  Stack,
  Divider,
  List,
  Link,
  ListItem,
  Heading,
  HStack,
  Text,
} from '@chakra-ui/react'

import Form from './Form'

const Footer = () => {
  return (
    <Box
      backgroundColor={'#126F6A'}
      pt={['31px', '31px', '31px', '50px']}
      pb={['43px', '43px', '43px', '45px']}
      color={'#fff'}
    >
      <Container px={0} width={'82%'} maxWidth={1196} mx={'auto'}>
        <VStack spacing={['50px', '50px', '50px', '70px']}>
          <Img src={'/logo-icon.svg'} />
          <Stack
            direction={[
              'column-reverse',
              'column-reverse',
              'column-reverse',
              'row',
            ]}
            spacing={['56px', '56px', '56px', '']}
            width={'100%'}
            justify={'space-between'}
          >
            <HStack
              alignItems={'flex-start'}
              justifyContent={'space-between'}
              width={['100%', '100%', '100%', '50%']}
              maxWidth={['100%', '100%', '100%', '440px']}
              // spacing={['58px', '187px']}
            >
              <Heading fontWeight={500} fontSize={'16px'} lineHeight={'24px'}>
                <Link as={GatsbyLink} to={'/about-us'}>
                  About Us
                </Link>
              </Heading>
              <VStack alignItems={'flex-start'} spacing={'24px'}>
                <Heading fontWeight={500} fontSize={'16px'} lineHeight={'24px'}>
                  Contact Us
                </Heading>
                <List spacing={'24px'} fontWeight={500}>
                  <ListItem opacity={'60%'}>
                    <Link href={'mailto:admin@ursanex.com'}>
                      admin@ursanex.com
                    </Link>
                  </ListItem>
                  {/*<ListItem opacity={'60%'}>
                    <Link href={'tel:+18887998765'}>+1 (888) 799-8765</Link>
  </ListItem>*/}
                </List>
              </VStack>
            </HStack>
            <Box
              width={['100%', '100%', '100%', '50%']}
              maxWidth={['100%', '100%', '100%', '388px']}
            >
              <Stack direction={'column'} spacing={'20px'}>
                <Heading fontWeight={700} fontSize={'24px'} lineHeight={'36px'}>
                  Stay informed
                </Heading>
                <Form
                  portalId={'8163814'}
                  formGuid={'8bfbc954-8b19-4571-a98e-3ee5bcb81ad0'}
                />
              </Stack>
            </Box>
          </Stack>

          <Divider display={['none', 'none', 'none', 'block']} />
          <HStack
            mt={['', '', '', '21px']}
            width={'100%'}
            align={'flex-start'}
            opacity={0.6}
            fontWeight={400}
            fontSize={['10px', '10px', '10px', '12px']}
            lineHeight={['15px', '15px', '15px', '18px']}
            letterSpacing={'0.5px'}
            spacing={['12px', '12px', '12px', '22px']}
          >
            <Text>&copy; 2021 Ursanex</Text>
            <Link
              href={
                'https://www.termsfeed.com/live/2a802abe-9618-4360-99dd-5fc0e509072d'
              }
              target={'_blank'}
            >
              Privacy Policy
            </Link>
            <Link
              href={
                'https://www.termsfeed.com/live/7734578f-cfd3-4514-9a79-8786e4ceea1d'
              }
              target={'_blank'}
            >
              Terms &amp; Conditions
            </Link>
          </HStack>
        </VStack>
      </Container>
    </Box>
  )
}

export default Footer
