import React from 'react'
import { Box, Container, Heading, VStack } from '@chakra-ui/react'
import { GetStartedButton } from '../../../components'

const Section6: React.FC = () => {
  return (
    <Box
      backgroundColor={'#F9F9F9'}
      py={['72px', '72px', '72px', '127px']}
      borderTop={'2px solid rgba(0, 0, 0, 0.1);'}
    >
      <Container width={'82%'} maxWidth={'645px'}>
        <VStack spacing={'40px'}>
          <Heading
            textAlign={'center'}
            color={'#000000'}
            fontWeight={700}
            fontSize={['32px', '32px', '32px', '36px']}
            lineHeight={'45px'}
          >
            Unlock clinical insights that will set your venture up for success.
          </Heading>
          <GetStartedButton />
        </VStack>
      </Container>
    </Box>
  )
}

export default Section6
