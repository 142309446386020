import React from 'react'
import { VStack, HStack, Heading, Text, Box } from '@chakra-ui/react'

interface IStepCardProps {
  number: number
  heading: string
  body: string
  colors?: {
    background: string
    numberCircleBackground: string
  }
}

const StepCard: React.FC<IStepCardProps> = ({
  number,
  heading,
  body,
  colors = {
    background: '#126F69',
    numberCircleBackground: '#009E95',
  },
}) => {
  return (
    <HStack
      align={'flex-start'}
      backgroundColor={colors.background}
      color={'#fff'}
      pt={['10px', '14px']}
      pl={['10px', '25px']}
      pb={['22px', '25px']}
      pr={['10px', '25px']}
      spacing={['10px', '24px']}
    >
      <Box width={['25px', '51px']}>
        <Box
          backgroundColor={colors.numberCircleBackground}
          borderRadius={'50%'}
          width={['25px', '51px']}
          height={['25px', '51px']}
        >
          <Text
            fontWeight={600}
            fontSize={['14px', '30px']}
            height={['18px', '45px']}
            lineHeight={['24px', '52px']}
            textAlign={'center'}
          >
            {number}
          </Text>
        </Box>
      </Box>
      <VStack align={'flex-start'} spacing={'12px'} pt={['3.5px', '15px']}>
        <Heading
          fontSize={['16px', '18px']}
          lineHeight={'20px'}
          fontWeight={700}
        >
          {heading}
        </Heading>
        <Text fontWeight={300} fontSize={['16px', '18px']} lineHeight={'20px'}>
          {body}
        </Text>
      </VStack>
    </HStack>
  )
}

export default StepCard
