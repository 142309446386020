import React from 'react'
import { Layout } from '../../components'
import TitleSection from './TitleSection'
import Section1 from './Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import Section4 from './Section4'

const AboutUs: React.FC = () => {
  return (
    <Layout>
      <section id={'title'}>
        <TitleSection />
      </section>
      <section id={'section-1'}>
        <Section1 />
      </section>
      <section id={'section-2'}>
        <Section2 />
      </section>
      <section id={'section-3'}>
        <Section3 />
      </section>
      <section id={'section-4'}>
        <Section4 />
      </section>
    </Layout>
  )
}

export default AboutUs
